import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
  HttpBackend,
  HttpEventType,
} from '@angular/common/http';
import {
  Subscription,
  Observable,
  Subject,
  BehaviorSubject,
  combineLatest,
  EMPTY,
  firstValueFrom,
} from 'rxjs';
import { FormStatus } from '@app/models';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state';
import {
  CreateSuccess,
  UpdateSuccess,
  DeleteSuccess,
} from '@briebug/ngrx-auto-entity';

import { catchError, last, map, skip, tap, retry } from 'rxjs/operators';
import { FormStatusFacade } from '@app/facades/form-status.facade';

import { Filesystem, Directory } from '@capacitor/filesystem';
import { StorageService } from '@services/storage/storage.service';

export interface S3UploadMessages {
  connected: string;
  uploadProgress: string;
  uploadProgressComplete: string;
}

export interface S3UploadPayload {
  url: string;
  file: File;
  messages: S3UploadMessages;
  entityType: string;
  entityId: string;
  data?: any;
  onComplete: () => Observable<[boolean, boolean]>;
}

export interface S3Upload {
  httpRequest$: () => Subscription;
  httpSubscription$: Subscription;
  completed$: Subject<boolean>;
  payload: S3UploadPayload;
}

export interface InterruptedUpload {
  entityType: string;
  entityId: string;
  file: File;
}

export interface InterruptedUploadData {
  entityType: string;
  entityId: string;
  file: string;
}

@Injectable({
  providedIn: 'root',
})
export class S3UploadService {
  http: HttpClient;
  uploadQueue$ = new BehaviorSubject<Record<string, S3Upload>>({});
  currentUploads$ = new BehaviorSubject<Record<string, Subscription>>({});
  finishingUploads$ = new BehaviorSubject<string[]>([]);
  interruptingUploads$ = new BehaviorSubject<boolean>(false);
  activeUploads: Subject<boolean> = new Subject<boolean>();
  activeStatus: BehaviorSubject<string> = new BehaviorSubject<string>('none');
  expiration: number = null;
  startTime: number = null;

  uploadStatus$ = combineLatest([
    this.uploadQueue$.pipe(skip(1)),
    this.currentUploads$,
  ]).pipe(
    map(([uploads, currentUploads]) => {
      return {
        uploadQueueIds: Object.keys(uploads),
        currentUploadIds: Object.keys(currentUploads),
      };
    })
  );

  subs = new Subscription();

  maxUploads = 2;

  constructor(
    private handler: HttpBackend,
    private storage: StorageService,
    private store$: Store<AppState>,
    private formStatus: FormStatusFacade
  ) {
    // Create new http client that doesn't use interceptor
    this.http = new HttpClient(this.handler);
    this.activeUploads.next(false);
    this.updateActive();
    this.subs.add(
      this.uploadStatus$.subscribe(status => {
        if (
          status.uploadQueueIds.length === 0 &&
          status.currentUploadIds.length === 0
        ) {
          this.activeUploads.next(false);
          this.updateActive();
          this.startTime = null;
          this.expiration = null;
        } else if (status.currentUploadIds.length <= this.maxUploads) {
          // Non-uploading uploads = uploadIds not in subscriptionIds
          for (const id of status.uploadQueueIds) {
            if (!status.currentUploadIds.includes(id)) {
              this.startUpload(id);
              break;
            }
          }
        }
      })
    );
  }

  get activeUploads$() {
    return this.activeUploads.asObservable();
  }

  get activeStatus$() {
    return this.activeStatus;
  }

  updateActive() {
    if (this.startTime !== null && this.expiration !== null) {
      if (new Date().getTime() - this.startTime > this.expiration) {
        this.startTime = null;
        this.expiration = null;
        this.activeStatus.next('expired');
      }
    } else if (Object.keys(this.uploadQueue$.getValue()).length > 0) {
      this.activeStatus.next('uploading');
    } else if (this.finishingUploads$.getValue().length > 0) {
      this.activeStatus.next('finishing');
    } else {
      this.activeStatus.next('none');
    }
  }

  setExpiration(expTime: number) {
    this.startTime = new Date().getTime();
    this.expiration = expTime;
  }

  cancelExperiation() {
    this.expiration = null;
    this.startTime = null;
  }

  upload(payload: S3UploadPayload, redo = false): Observable<boolean> {
    const completed$ = new Subject<boolean>();

    const headers = new HttpHeaders({ 'Content-Type': payload.file.type });
    const req = new HttpRequest('PUT', payload.url, payload.file, {
      headers,
      reportProgress: true,
    });

    const upload: S3Upload = {
      httpRequest$: () =>
        this.http
          .request(req)
          .pipe(
            tap(event => this.processHttpEvent(event, payload)),
            last(),
            retry(6),
            catchError(error => this.handleError(error, payload))
          )
          .subscribe(event => {}),
      httpSubscription$: null,
      completed$,
      payload,
    };

    this.uploadQueue$.next({
      ...this.uploadQueue$.getValue(),
      [upload.payload.entityId]: upload,
    });
    return EMPTY;
    //return completed$.asObservable();
  }

  startUpload(entityId: string) {
    console.log(`Starting upload ${entityId} update`);
    const upload = this.uploadQueue$.getValue()[entityId];
    console.log(`--- Adding ${entityId} to currentUploads ---`);
    this.currentUploads$.next({
      ...this.currentUploads$.getValue(),
      [upload.payload.entityId]: upload.httpRequest$(),
    });
    this.activeUploads.next(true);
    this.updateActive();
  }

  async handleError(error: any, payload: S3UploadPayload) {
    console.log('--- S3Upload handleError ---');
    console.log(error);
    console.log(`----- ${payload.entityId} ------`);
    this.finishUploadError(payload.entityId);
  }

  processHttpEvent(event: HttpEvent<any>, payload: S3UploadPayload) {
    switch (event.type) {
      case HttpEventType.Sent: {
        const fs: FormStatus = {
          id: payload.entityId,
          fileSize: 100,
          uploaded: 0,
          message: payload.messages.connected,
          error: false,
          entityType: payload.entityType,
          entityId: payload.entityId,
        };
        if (payload.data) {
          fs.data = payload.data;
        }
        this.store$.dispatch(new CreateSuccess(FormStatus, fs));
        break;
      }
      case HttpEventType.UploadProgress: {
        const fs: FormStatus = {
          id: payload.entityId,
          fileSize: event.total,
          uploaded: event.loaded,
          message: payload.messages.uploadProgress,
          error: false,
          entityType: payload.entityType,
          entityId: payload.entityId,
        };
        console.log(
          `${payload.entityId.slice(0, 10)}: uploading...`,
          event.loaded / event.total,
          Object.keys(this.uploadQueue$.getValue()).length
        );
        if (payload.data) {
          fs.data = payload.data;
        }

        if (event.loaded === event.total) {
          console.log(`${payload.entityId.slice(0, 10)}: upload complete!`);
          fs.message = payload.messages.uploadProgressComplete;
        }
        this.updateActive();
        this.store$.dispatch(new UpdateSuccess(FormStatus, fs));
        break;
      }
      case HttpEventType.Response: {
        const fs: FormStatus = {
          id: payload.entityId,
          fileSize: 100,
          uploaded: 100,
          message: `Complete`,
          error: false,
          entityType: payload.entityType,
          entityId: payload.entityId,
        };
        if (payload.data) {
          fs.data = payload.data;
        }
        this.store$.dispatch(new DeleteSuccess(FormStatus, fs));
        this.finishUpload(payload.entityId);
        break;
      }
    }
  }

  finishUpload(id: string) {
    const upload = this.uploadQueue$.getValue()[id];
    upload.completed$.next(true);
    upload.completed$.complete();
    const finishingUploads = this.finishingUploads$.getValue();
    this.finishingUploads$.next([...finishingUploads, id]);
    const { [id]: _, ...remainingUploads } = this.uploadQueue$.getValue();
    this.uploadQueue$.next(remainingUploads);
    const {
      [id]: subscription,
      ...remainingSubscriptions
    } = this.currentUploads$.getValue();
    this.currentUploads$.next(remainingSubscriptions);
    subscription.unsubscribe();
    this.updateActive();
    upload.payload
      .onComplete()
      .toPromise()
      .then(resp => {
        const finishingUploads = this.finishingUploads$.getValue();
        this.finishingUploads$.next([
          ...finishingUploads.filter(ulId => ulId !== id),
        ]);
        this.updateActive();
      });
  }

  finishUploadError(id: string) {
    console.log('------ finishUploadError --------');
    const upload = this.uploadQueue$.getValue()[id];
    upload.completed$.next(false);
    upload.completed$.complete();

    const { [id]: _, ...remainingUploads } = this.uploadQueue$.getValue();
    this.uploadQueue$.next(remainingUploads);
    const {
      [id]: subscription,
      ...remainingSubscriptions
    } = this.currentUploads$.getValue();
    this.currentUploads$.next(remainingSubscriptions);
    subscription.unsubscribe();
    // If error, add the upload back into the queue to try again later.
    firstValueFrom(this.upload(upload.payload, true)).then(() => {
      console.log('Added');
    });
  }

  async interruptUploads() {
    this.interruptingUploads$.next(true);
    const currentQueue = this.uploadQueue$.getValue();
    this.uploadQueue$.next({});
    const currentUploads = this.currentUploads$.getValue();

    const ids = Object.keys(currentQueue);
    const uploads: InterruptedUpload[] = ids.map(id => {
      const upload = currentQueue[id];
      if (id in currentUploads) {
        currentUploads[id].unsubscribe();
      }
      return {
        entityId: upload.payload.entityId,
        entityType: upload.payload.entityType,
        file: upload.payload.file,
      };
    });
    this.currentUploads$.next({});
    await this.storeInterruptedUploads(uploads);
    this.interruptingUploads$.next(false);
  }

  async storeInterruptedUploads(uploads: InterruptedUpload[]) {
    console.log('storeInterruptedUploads....');
    const uploadDat: InterruptedUploadData[] = [];
    this.formStatus.clear();
    for (const upload of uploads) {
      const data = (await this.toBase64(upload.file)) as string;
      console.log(`  File Name- ${upload.file.name}`);
      await Filesystem.writeFile({
        data: data,
        path: `${upload.file.name}`,
        directory: Directory.Data,
      });
      const payload: InterruptedUploadData = {
        entityId: upload.entityId,
        entityType: upload.entityType,
        file: upload.file.name,
      };
      console.log(`   payload: ${payload}`);
      uploadDat.push(payload);
    }
    const dataString = JSON.stringify(uploadDat);
    await this.storage.set('INCOMPLETE_UL', dataString);
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
}
