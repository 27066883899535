import { JobService, LinkEquipmentRequest } from '@services/api/job.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, createAction, props } from '@ngrx/store';
import { AppState } from './app.state';
import {
  JobPlanService,
  CreateJobPlanPayload,
  LinkEmergencyJobRequest,
  DeleteJobPlanRequest,
  AddJobPlanManagerPayload,
} from '@services/api/job-plan.service';
import { map, exhaustMap, concatMap, filter, take } from 'rxjs/operators';
import {
  CreateSuccess,
  UpdateSuccess,
  LoadManySuccess,
  LoadSuccess,
  DeleteSuccess,
  CreateManySuccess,
  DeleteByKeySuccess,
  DeleteManySuccess,
} from '@briebug/ngrx-auto-entity';
import {
  JobPlan,
  JobPlanManager,
  Job,
  JobPlanManaged,
  JobPlanVM,
  JobPlanCrewRequirement,
  JobPlanCrewAssignment,
  Crew,
  CreateCrewReqPayload,
  JobPlanEquipReq,
  JobLocate,
  EmergencyTicket,
  JobPlanSiteEquipment,
  JobPlanLinkedEquip,
} from '@app/models';
import { format } from 'date-fns';
import {
  createJobPlanCrewAssignments,
  deleteCrewAssignment,
} from './job-plan-crew-assignment.effects';
import { JobPlanEquipReqFacade } from '@app/facades/job-plan-equip-req.facade';
import { JobPlanCrewReqFacade } from '@app/facades/job-plan-crew-req.facade';
import { jobPlanUpdateEquipReq } from './job-plan-equip-req.state';
import { jobPlanUpdateCrewReq } from './job-plan-crew-req.state';
import { jobReplaceByNumber } from './job.state';

export const getJobPlanSummary = createAction(
  '[Job Plan] Get summary',
  props<{ payload: string }>()
);

export const getJobPlanManagers = createAction(
  '[Job Plan] Get managers',
  props<{ payload: string }>()
);

export const createJobPlan = createAction(
  '[Job Plan] Create Job Plan',
  props<{ payload: CreateJobPlanPayload }>()
);

export const deleteManaged = createAction(
  '[Job Plan] Delete Managed Job Plan',
  props<{ payload: DeleteJobPlanRequest }>()
);

export const linkEmergencyTicket = createAction(
  '[Job Plan] Link Emergency Ticket',
  props<{ payload: LinkEmergencyJobRequest }>()
);

export const updateJobPlan = createAction(
  '[Job Plan] Update Job Plan',
  props<{ payload: JobPlan }>()
);

export const getJobPlanDetails = createAction(
  '[Job Plan] Get Job Plan Details',
  props<{ payload: JobPlan }>()
);

export const getJobPlanDetailsSuccess = createAction(
  '[Job Plan] Get Job Plan Details Success',
  props<{ payload: JobPlan }>()
);

export const createJobPlanCrewReq = createAction(
  '[Job Plan] Create Crew Requirement',
  props<{ payload: CreateCrewReqPayload }>()
);

export const updateJobPlanCrewReq = createAction(
  '[Job Plan] Update Crew Requirement',
  props<{ payload: JobPlanCrewRequirement }>()
);

export const deleteJobPlanCrewReq = createAction(
  '[Job Plan] Delete Crew Requirement',
  props<{ payload: JobPlanCrewRequirement }>()
);

export const createJobPlanEquipReqs = createAction(
  '[Job Plan] Create Equipment Requirement',
  props<{ payload: JobPlanEquipReq[] }>()
);

export const updateJobPlanEquipReq = createAction(
  '[Job Plan] Update Equipment Requirement',
  props<{ payload: JobPlanEquipReq }>()
);

export const deleteJobPlanEquipReq = createAction(
  '[Job Plan] Delete Equipment Requirement',
  props<{ payload: JobPlanEquipReq }>()
);

export const linkEquipment = createAction(
  '[Job Plan] Link Equipment to Requirement',
  props<{ payload: LinkEquipmentRequest }>()
);

export const createLinkedEquipment = createAction(
  '[Job Plan] Create Linked Equipment',
  props<{ payload: JobPlanLinkedEquip }>()
);

export const updateLinkedEquipment = createAction(
  '[Job Plan] Update Linked Equipment',
  props<{ payload: JobPlanLinkedEquip }>()
);

export const deleteLinkedEquipment = createAction(
  '[Job Plan] Delete Linked Equipment',
  props<{ payload: string }>()
);

export const addJobPlanManager = createAction(
  '[Job Plan] Add Manager',
  props<{ payload: AddJobPlanManagerPayload }>()
);

export const uncompleteJobPlan = createAction(
  '[Job Plan] Uncomplete',
  props<{ payload: { jobPlanId: string } }>()
);

@Injectable()
export class JobPlanEffects {
  createJobPlanCrewReq$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createJobPlanCrewReq),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.createCrewReq(payload.crewReq).pipe(
            map(response => {
              this.store$.dispatch(
                new CreateSuccess(JobPlanCrewRequirement, response.crewReq)
              );
              this.store$.dispatch(
                new LoadSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
              const crewReqId = response.crewReq.id;
              const crewAssignments = payload.crewAssignments.map(ca => ({
                ...ca,
                jpCrewReq: crewReqId,
              }));
              if (crewAssignments.length > 0) {
                this.store$.dispatch(
                  createJobPlanCrewAssignments({ payload: crewAssignments })
                );
              }
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  getSummary$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getJobPlanSummary),
        map(action => action.payload),
        exhaustMap(id =>
          this.jobPlanService.summary(id).pipe(
            map(response => {
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
              this.store$.dispatch(jobReplaceByNumber({ job: response.job }));
            })
          )
        )
      ),
    { dispatch: false }
  );

  getManagers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getJobPlanManagers),
        map(action => action.payload),
        exhaustMap(id =>
          this.jobPlanService.managedBy(id).pipe(
            map(response => {
              this.store$.dispatch(
                new LoadManySuccess(JobPlanManaged, response)
              );
            })
          )
        )
      ),
    { dispatch: false }
  );

  createJobPlanEquipReqs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createJobPlanEquipReqs),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.createManyEquipReqs(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new CreateManySuccess(
                  JobPlanEquipReq,
                  response.equipmentRequests
                )
              );
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
              this.jobPlanService.refreshEquipUsage();
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  updateJobPlanCrewReq$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateJobPlanCrewReq),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.updateCrewReq(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new UpdateSuccess(JobPlanCrewRequirement, response.crewReq)
              );
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  updateJobPlanEquipReq$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateJobPlanEquipReq),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.updateEquipReq(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new UpdateSuccess(JobPlanEquipReq, response.equipmentRequest)
              );
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
              this.jobPlanService.refreshEquipUsage();
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  deleteJobPlanCrewReq$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteJobPlanCrewReq),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.deleteCrewReq(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new DeleteSuccess(JobPlanCrewRequirement, payload)
              );
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  deleteJobPlanEquipReq$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteJobPlanEquipReq),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.deleteEquipReq(payload).pipe(
            map(response => {
              this.store$.dispatch(new DeleteSuccess(JobPlanEquipReq, payload));
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
              this.jobPlanService.refreshEquipUsage();
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  deleteManagedJobPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteManaged),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.deleteManaged(payload).pipe(
            map(response => {
              if (response.jobPlanManagedDeleted) {
                this.store$.dispatch(
                  new DeleteSuccess(
                    JobPlanManaged,
                    response.jobPlanManagedDeleted
                  )
                );
              }
              if (response.jobPlanDeleted) {
                this.store$.dispatch(
                  new DeleteSuccess(JobPlan, response.jobPlanDeleted)
                );
              }
              this.store$.dispatch(new UpdateSuccess(Job, response.job));
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  addJobPlanManager$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addJobPlanManager),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.addManager(payload).pipe(
            map(response => {
              this.store$.dispatch(new LoadSuccess(JobPlan, response.jobPlan));
              this.store$.dispatch(new LoadSuccess(Job, response.job));
              this.store$.dispatch(
                new CreateSuccess(JobPlanManaged, response.jobPlanManaged)
              );
              if ('manager' in response) {
                this.store$.dispatch(
                  new LoadSuccess(JobPlanManager, response.manager)
                );
              }
            })
          )
        )
      ),
    { dispatch: false }
  );

  uncompleteJobPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uncompleteJobPlan),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.uncomplete(payload).pipe(
            map(response => {
              this.store$.dispatch(new LoadSuccess(JobPlan, response.jobPlan));
              this.store$.dispatch(new LoadSuccess(Job, response.job));
              this.store$.dispatch(
                new CreateManySuccess(JobPlanManaged, response.jobPlanManaged)
              );
            })
          )
        )
      ),
    { dispatch: false }
  );

  createJobPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createJobPlan),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.createWithManager(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new CreateSuccess(JobPlan, response.jobPlan)
              );
              this.store$.dispatch(new LoadSuccess(Job, response.job));
              this.store$.dispatch(
                new CreateSuccess(JobPlanManaged, response.jobPlanManaged)
              );
              if ('manager' in response) {
                this.store$.dispatch(
                  new LoadSuccess(JobPlanManager, response.manager)
                );
              }
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  linkEmergencyTicket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(linkEmergencyTicket),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.linkEmergencyJob(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, response.jobPlan)
              );
              this.store$.dispatch(new UpdateSuccess(Job, response.job));
              this.store$.dispatch(
                new UpdateSuccess(EmergencyTicket, response.ticket)
              );
              this.store$.dispatch(new DeleteSuccess(Job, payload.repJob));
              this.store$.dispatch(
                new CreateSuccess(JobPlanManaged, response.jobPlanManaged)
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  linkEquipment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(linkEquipment),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobService.linkEquipment(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, response.jobPlan)
              );
              this.store$.dispatch(
                new CreateSuccess(JobPlanEquipReq, response.equipReq)
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  updateJobPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateJobPlan),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.replace(null, payload).pipe(
            map(response => {
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, {
                  ...response,
                  details: payload.details,
                })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  getJobPlanDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getJobPlanDetails),
        map(action => action.payload),
        concatMap(payload => {
          const completedDate =
            payload.completedDate === null
              ? null
              : typeof payload.completedDate === 'string'
              ? payload.completedDate
              : format(payload.completedDate, 'yyyy-MM-dd');
          const jp: JobPlan = {
            ...payload,
            details: 'loading',
          };
          this.store$.dispatch(new UpdateSuccess(JobPlan, jp));
          return this.jobPlanService.getDetails(payload).pipe(
            map(response => {
              // Special action to properly update Crew Reqs for
              // a sepecific jobplan.
              this.store$.dispatch(
                jobPlanUpdateCrewReq({
                  crewReqs: response.crewRequirements,
                })
              );

              this.store$.dispatch(
                new LoadManySuccess(
                  JobPlanCrewAssignment,
                  response.crewAssignments
                )
              );

              this.store$.dispatch(new LoadManySuccess(Crew, response.crews));

              // Special action to properly update Equip Reqs for
              // a sepecific jobplan.
              this.store$.dispatch(
                jobPlanUpdateEquipReq({
                  equipReqs: response.equipmentRequirements,
                })
              );

              this.store$.dispatch(
                new LoadManySuccess(JobLocate, response.locates)
              );
              this.store$.dispatch(
                new LoadManySuccess(JobPlanSiteEquipment, response.jobEquipment)
              );
              this.store$.dispatch(
                new LoadManySuccess(
                  JobPlanLinkedEquip,
                  response.linkedEquipment
                )
              );
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, { ...jp, details: 'loaded' })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          );
        })
      ),
    { dispatch: false }
  );

  deleteCrewAssignment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteCrewAssignment),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.deleteCrewAssignment(payload.crewAssign).pipe(
            map(response => {
              this.store$.dispatch(
                new DeleteSuccess(JobPlanCrewAssignment, payload.crewAssign)
              );
              if (payload.crewReq) {
                this.store$.dispatch(
                  updateJobPlanCrewReq({ payload: payload.crewReq })
                );
                // this.store$.dispatch(
                //   new Update(JobPlanCrewRequirement, payload.crewReq)
                // );
              } else {
                this.store$.dispatch(
                  new LoadSuccess(JobPlan, { ...response, details: 'loaded' })
                );
              }
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  createlinkedEquipment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createLinkedEquipment),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.createLinkedEquip(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new CreateSuccess(JobPlanLinkedEquip, response.linkedEquipment)
              );
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
              this.store$.dispatch(
                new LoadSuccess(JobPlanEquipReq, response.equipReq)
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  updateJobPlanLinkedEquip$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateLinkedEquipment),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.updateLinkedEquip(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new UpdateSuccess(JobPlanLinkedEquip, response.linkedEquipment)
              );
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  deleteJobPlanLinkedEquip$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteLinkedEquipment),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.deleteLinkedEquip(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new DeleteByKeySuccess(JobPlanLinkedEquip, payload)
              );
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private jobPlanService: JobPlanService,
    private jobService: JobService,
    private equipReqFacade: JobPlanEquipReqFacade,
    private crewReqFacade: JobPlanCrewReqFacade,
    private store$: Store<AppState>
  ) {}
}
