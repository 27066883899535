import { Store } from '@ngrx/store';
import { UserMe } from './../../models/user-me.model';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import {
  loadUserMeSuccess,
  loadUserMeError,
  loadUserMe,
} from './user-me.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { UserMeService } from '@services/api/user-me.service';
import { of } from 'rxjs';
import { LoadSuccess } from '@briebug/ngrx-auto-entity';
import { Job } from '@app/models';
import { AppState } from '../app.state';

@Injectable()
export class UserMeEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserMe),
      mergeMap(() =>
        this.userMeService.load().pipe(
          map(res => {
            const userMe: UserMe = {
              user: res.user,
              incompleteCount: res.incompleteCount,
              activeTimecard: res.activeTimecard,
              minDate: res.minDate,
              invalidRanges: res.invalidRanges,
            };
            if (res.job) {
              this.store$.dispatch(new LoadSuccess(Job, res.job));
            }
            return loadUserMeSuccess({ payload: userMe });
          }),
          catchError(err => of(loadUserMeError(err)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userMeService: UserMeService,
    private store$: Store<AppState>
  ) {}
}
