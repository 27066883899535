import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import {
  PublicRestorationTicket,
  PublicRestorationTicketResponse,
  Restoration,
} from '@app/models';

@Injectable({ providedIn: 'root' })
export class RestorationService {
  constructor(private http: HttpClient, private api: ApiService) {}

  loadAll(): Observable<Restoration> {
    return this.http.get<Restoration>(`${this.api.url}/restoration`);
  }

  publicTicket(id: string): Observable<PublicRestorationTicketResponse> {
    return this.http.get<PublicRestorationTicketResponse>(
      `http://localhost:8000/api/v1/restoration-tickets/${id}`
    );
  }

  completeTicket(id: string): Observable<PublicRestorationTicket> {
    return this.http.post<PublicRestorationTicket>(
      `http://localhost:8000/api/v1/restoration-tickets/${id}/complete`,
      {}
    );
  }
}
