import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { BehaviorSubject } from 'rxjs';
import { delay, retryWhen } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root',
})
export class ServerStatusWsService {
  subject: any;
  url: string;
  maintenanceSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private api: ApiService) {}

  setupWebSocket() {
    this.url = `${this.api.wsUrl}/server-status`;
    this.subject = webSocket({
      url: this.url,
      openObserver: {
        next: () => {
          console.log(`Connected to websocket at ${this.url}`);
        },
      },
    });

    this.subject
      .pipe(
        retryWhen(err => {
          console.log('Disconnected!  Attempting reconnection shortly...');
          return err.pipe(delay(2000));
        })
      )
      .subscribe((msg: { maintenance: boolean }) => {
        this.maintenanceSubject.next(msg.maintenance);
      });
  }

  get maintenance$() {
    return this.maintenanceSubject.asObservable();
  }
}
