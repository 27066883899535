import { CreateJobLocateModalComponent } from './create-job-locate-modal/create-job-locate-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmPopoverComponent } from './confirm-popover/confirm-popover.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ActiveTimecardComponent } from './active-timecard/active-timecard.component';
import { UpdateStatusComponent } from './update-status/update-status.component';
import { HoursMinutesPickerComponent } from './controls/hours-minutes-picker/hours-minutes-picker.component';
import { HoursMinutesModalComponent } from './controls/hours-minutes-picker/hours-minutes-modal/hours-minutes-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconPopoverComponent } from './icon-popover/icon-popover.component';
import { MultipleTimecardsModalComponent } from './multiple-timecards-modal/multiple-timecards-modal.component';
import { JobAlertsComponent } from './job-alerts/job-alerts.component';
import { JobAlertsListModalComponent } from './job-alerts/components/job-alerts-list-modal/job-alerts-list-modal.component';
import { EventAlertButtonComponent } from './event-alert-button/event-alert-button.component';
import { EventListPopoverComponent } from './event-alert-button/components/event-list-popover/event-list-popover.component';
import { MbscDatepickerModule } from '@mobiscroll/angular';
import { PanelSpinnerComponent } from './panel-spinner/panel-spinner.component';
import { TimeDurationPickerComponent } from './controls/time-duration-picker/time-duration-picker.component';

@NgModule({
  declarations: [
    ConfirmPopoverComponent,
    CreateJobLocateModalComponent,
    ActiveTimecardComponent,
    UpdateStatusComponent,
    HoursMinutesPickerComponent,
    HoursMinutesModalComponent,
    TimeDurationPickerComponent,
    IconPopoverComponent,
    MultipleTimecardsModalComponent,
    JobAlertsComponent,
    JobAlertsListModalComponent,
    EventAlertButtonComponent,
    EventListPopoverComponent,
    PanelSpinnerComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MbscDatepickerModule,
  ],
  exports: [
    ActiveTimecardComponent,
    UpdateStatusComponent,
    HoursMinutesPickerComponent,
    TimeDurationPickerComponent,
    MultipleTimecardsModalComponent,
    JobAlertsComponent,
    EventAlertButtonComponent,
    PanelSpinnerComponent,
  ],
})
export class SharedComponentsModule {}
