import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ContractExtra,
  ContractExtraFieldLog,
  ContractExtraTotalTime,
  Job,
  JobDateEquipOptTimecard,
  JobDateEquipTimecard,
  JobMaterial,
  JobSubcontractor,
} from '@app/models';
import { ApiService } from './api.service';

export interface ContractExtraResponse {
  contractExtras: ContractExtra[];
  jobs: Job[];
}

export interface CreateContractExtraResponse {
  contractExtra: ContractExtra;
  job: Job;
}

export interface JobContractExtrasResponse {
  extras: ContractExtra[];
  job: Job;
}

export interface ContractExtraDetailsResponse {
  extra: ContractExtra;
  fieldLogs: ContractExtraFieldLog[];
  totalTime: ContractExtraTotalTime[];
  equip: JobDateEquipTimecard[];
  equipOpt: JobDateEquipOptTimecard[];
  materials: JobMaterial[];
  subcontractors: JobSubcontractor[];
}

export interface ContratExtraAssignResponse {
  extra: ContractExtra;
  fieldLogs: ContractExtraFieldLog[];
  deletedExtraId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContractExtraService {
  constructor(private http: HttpClient, private api: ApiService) {}

  loadAll() {
    return this.http.get<ContractExtraResponse>(
      `${this.api.url}/jobs/contract-extras`
    );
  }

  create(payload: ContractExtra) {
    return this.http.post<CreateContractExtraResponse>(
      `${this.api.url}/jobs/contract-extras`,
      payload
    );
  }

  loadByJob(jobId: string) {
    return this.http.get<JobContractExtrasResponse>(
      `${this.api.url}/jobs/${jobId}/contract-extras`
    );
  }

  loadExtraDetails(extraId: string) {
    return this.http.get<ContractExtraDetailsResponse>(
      `${this.api.url}/jobs/contract-extras/${extraId}/field-logs`
    );
  }

  assign(extraId: string, assignTo: string) {
    return this.http.post<ContratExtraAssignResponse>(
      `${this.api.url}/jobs/contract-extras/${extraId}/assign`,
      { assignTo }
    );
  }
}
