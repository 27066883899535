import { createSelector } from '@ngrx/store';
import { jobSelectors } from '../job.state';
import { selectMultipleTimeCards } from './multiple-timecards.selectors';

export const selectMultipleTimeCardJobs = createSelector(
  selectMultipleTimeCards,
  jobSelectors.selectEntities,
  (multipleTimeCards, jobs) => {
    return multipleTimeCards.timecards
      .filter(timecard => timecard.job)
      .reduce((acc, timecard) => {
        acc[timecard.job] = jobs[timecard.job];
        return acc;
      }, {});
  }
);
